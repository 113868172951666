import { Link } from 'gatsby'
import * as React from 'react'

import LogoImage from '../../images/Logo'
import styled from '../styled'

export const Logo = styled.h1`
  display: flex;
  align-items: center;

  span {
    text-transform: uppercase;
    line-height: 1;
    margin-left: 0.5em;
    margin-top: 0.3em;
  }
`

export default function LogoWrapper() {
  return (
    <Link to="/">
      <Logo>
        <LogoImage />
        <span>Akasha</span>
      </Logo>
    </Link>
  )
}
