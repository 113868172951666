import * as React from 'react'

import { Box } from '../../components/shared/Helpers'
import { IProp as IBox } from '../../components/shared/Helpers/types'

type IWays = 'up' | 'down' | 'left' | 'right'

interface IProp extends IBox {
  direction?: IWays
}

const directions = (way: IWays) => {
  const ways: { [key in IWays]: number } = {
    down: 0,
    left: 90,
    right: -90,
    up: 180,
  }

  return ways[way] || ways.down
}

export default ({ direction = 'right', ...props }: IProp) => {
  return (
    <Box
      style={{
        display: 'inline-block',
        transform: `rotate(${directions(direction)}deg)`,
      }}
      as="span"
      {...props}
    >
      <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: '100%',
        }}
      >
        <g
          strokeWidth="1.5"
          stroke="currentColor"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
        >
          <path d="M13.25.875L7 7.125M.75.875L7 7.125" />
        </g>
      </svg>
    </Box>
  )
}
