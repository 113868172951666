import styled from '../styled'

export const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.grayscale.lighter};
  border-radius: 4px;
  margin-top: 1em;
  padding: 1em;

  button {
    white-space: nowrap;
  }
`
