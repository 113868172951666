import { Box, Flex } from '../../shared/Helpers'
import styled, { createGlobalStyle, css, keyframes } from '../../styled'

import { IMenuHandle } from '../types'

export const BlockBody = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`

export const Wrapper = styled.div<IMenuHandle>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  ${({ theme: { breakpoints } }) =>
    css`
      @media screen and (min-width: ${breakpoints[1]}) {
        display: none;
      }
    `}

  ${({ opened }) =>
    opened &&
    css`
      min-height: 100vh;
      position: fixed;
      bottom: 0;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      z-index: 9;
    `};
`

const navigation = keyframes`
  to {
    opacity: 1;
  }
`

export const HeaderMobile = styled(Box)<IMenuHandle>`
  background-color: ${({ theme: { colors } }) => colors.blue.dark};
  color: white;
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  opacity: 0;
  animation: ${navigation} 0.2s ease-in-out forwards;
  z-index: 100;
`

export const HeaderMobileAssign = styled(Flex)`
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
  border-top: 1px solid ${({ theme: { colors } }) => colors.grayscale.dark};
`
