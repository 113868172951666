import { Link } from 'gatsby'
import * as React from 'react'

import { Context } from '../Consumer'
import { IRegexCollection } from '../types'

const nodeRegex = new RegExp(
  /(<glossary-node id=".*?">.*?<\/glossary-node>)/,
  'gmi'
)

const idRegex = new RegExp(/<glossary-node id="(.*?)">.*?<\/glossary-node>/)

const textRegex = new RegExp(/<glossary-node id=".*?">(.*?)<\/glossary-node>/)

export const formatMessage = (
  { description, slug }: IRegexCollection,
  nodeText: string,
  jsx: boolean,
  show: boolean
) => {
  let tooltip: React.ReactNode = ''

  if (jsx) {
    tooltip = (
      <span className="glossary__tooltip">
        <span>
          {description}
          <Link to={`/glossary/#${slug}`}>Read more</Link>
        </span>
      </span>
    )

    return (
      <abbr title={!show ? description : undefined}>
        <span className="glossary__term"> {nodeText} </span>
        {show && tooltip}
      </abbr>
    )
  }

  tooltip = `<span class="glossary__tooltip">
        <span>
          ${description}
          <a href="/glossary/#${slug}">Read more</a>
        </span>
      </span>`

  return `<abbr ${!show ? `title="${description}"` : ''}>
      <span class="glossary__term"> ${nodeText} </span>
      ${show ? tooltip : ''}
    </abbr>`
}

interface IContext {
  collectionTerms: IRegexCollection[]
}

export default (message: string, jsx = false, show: boolean = false) => {
  const { collectionTerms }: IContext = React.useContext(Context)

  // Replace all terms by id, which will give:
  // - Order terms; - Several mention in the phrase; - Avoid wrong replaces;
  const messageWithId = collectionTerms.reduce((prev, curr) => {
    const tagGlossary = prev.match(
      new RegExp(/<glossary-node id=".*?">.*?<\/glossary-node>/gim)
    )

    return prev.replace(
      new RegExp(curr.regexInclude, 'gmi'),
      (node, item, offset) => {
        // avoid retag
        if (
          tagGlossary &&
          tagGlossary.length &&
          tagGlossary.filter(t => new RegExp(node).test(t)).length > 0
        ) {
          return node
        }

        const ir = new RegExp('(?:^|\\b)' + node + '(?!\\w)', 'gmi')
        const result = ir.exec(prev)
        const excl =
          typeof curr.regexExclude === 'string' &&
          new RegExp(curr.regexExclude, 'gmi').exec(prev)

        if (result) {
          if (excl) {
            const exid = excl.index
            const exl = excl.index + excl[0].length

            if (exid <= offset && offset <= exl) {
              return node
            } else {
              return `<glossary-node id="${
                curr.id
              }">${node.trim()}</glossary-node>`
            }
          } else {
            return `<glossary-node id="${
              curr.id
            }">${node.trim()}</glossary-node>`
          }
        }

        return node
      }
    )
  }, message)

  const formatted = messageWithId.split(nodeRegex)

  return formatted.map(node => {
    const id = node.match(idRegex)
    const nodeText = node.match(textRegex)

    if (id && id.length > 0 && nodeText && nodeText.length > 0) {
      // Get the term, by id
      const findTerm = collectionTerms.find(e => e.id === id[1])

      if (!findTerm) {
        return node
      }

      // Id to html
      return formatMessage(findTerm, nodeText[1], jsx, show)
    }
    return node
  })
}
