import styled, { createGlobalStyle, css, keyframes } from '../styled'

import { tagStyle } from '../shared/Typography'

const arrow = require('../../images/icons/arrow-blue.svg')

export const BlockBody = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`

const fadeIn = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`

export const Modal = styled.div`
  background-color: rgba(255, 255, 255, 0.98);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  animation: ${fadeIn} ${({ theme: { transition } }) => transition.ease(400)};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 46em;
  margin: 0 auto;
  padding-top: 10em;

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints[0]}) {
    padding: 3em 1.5em;
  }
`

export const SearchButton = styled.button`
  margin-left: 1em;
  margin-top: 2px;

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints[0]}) {
    margin-top: 0.3em;
    margin-right: 3em;
  }
`

export const CloseButton = styled.button`
  position: fixed;
  top: 2vw;
  right: 10vw;

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints[0]}) {
    position: absolute;
    top: 2em;
    right: 2em;
  }
`

export const Tab = styled.div`
  margin-bottom: 3em;
  white-space: nowrap;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  &:after {
    content: '';
    border-bottom: 1px solid
      ${({ theme: { colors } }) => colors.grayscale.light};
    width: 100%;
    display: block;
  }
`

export const TabActived = styled.p`
  ${({ theme: { colors } }) => css`
    color: ${colors.blue.default};
    border-bottom: 1px solid ${colors.blue.default};
    display: inline-block;
    margin-bottom: -1px;
    padding: 0.5em 1em;
  `}
`

export const Div = styled.div`
  margin-bottom: 2em;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.grayscale.light};
`

export const CustomStyle = styled.span`
  ${({ theme: { colors, transition } }) => css`
    .ais-SearchBox-input {
      height: 3em;
      line-height: 3.2em;
      margin-top: 0.4em;
      margin-bottom: 2em;
      width: 100%;
      border-radius: 3px;
      padding: 0 1em;
      border: 1px solid ${colors.grayscale.light};
      background-color: ${colors.grayscale.white};
    }

    .ais-InfiniteHits {
      padding-bottom: 6em;
    }

  

    .ais-Highlight-highlighted {
      font-weight: bold;
      font-style: normal;
      color: ${colors.blue.default}
    }
    
    .ais-InfiniteHits-item:first-child ${Div},
    .ais-CurrentRefinements,
    .ais-Menu-count,
    .ais-InfiniteHits-loadMore--disabled,
    .ais-SearchBox-reset,
    .ais-SearchBox-submit {
      display: none;
    }

    .ais-Menu,
    .ais-ClearRefinements {
      display: inline-block;
      vertical-align: bottom;
    }

    .ais-ClearRefinements-button,
    .ais-Menu-item {
      display: inline-block;
      margin-bottom: -1px;
      line-height: 1.5;
      padding: 0.5em 1em;
      color: ${colors.grayscale.default};
      transition: ${transition.ease()};
      border-bottom: 1px solid ${colors.grayscale.light};
    }

    .ais-ClearRefinements-button--disabled,
    .ais-Menu-item--selected {
      color: ${colors.blue.default};
      border-bottom: 1px solid ${colors.blue.default};
    }

    .ais-InfiniteHits-loadMore {
      ${tagStyle}
      color: ${colors.blue.default};

      &:before {
        content: "";
        background: url(${arrow});
        background-size: cover;
        width: 13px;
        height: 9px;
        display: inline-block;
        transform: rotate(90deg);
        margin-right: .6em;
      }
    }
  `}
`
