import styled, { css } from '../../styled'

import { Box } from '../Helpers'

export const Container = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  max-width: 70em;
  padding-left: 1.25em;
  padding-right: 1.25em;

  ${({ theme: { breakpoints } }) => css`
    @media screen and (min-width: ${breakpoints[0]}) {
      padding-left: 2em;
      padding-right: 2em;
    }
  `};
`
