import { Link } from 'gatsby'
import * as React from 'react'

import { textLinkStyle, textStyle } from '../../shared/Typography'
import styled, { css } from '../../styled'
import { IMenuHandle, IProp } from '../types'

export const Logo = styled.h1`
  display: flex;
  align-items: center;

  span {
    text-transform: uppercase;
    line-height: 1;
    margin-left: 0.5em;
    margin-top: 0.3em;
  }
`

export const Subnav = styled.div<IMenuHandle>`
  z-index: 999;
  position: absolute;
  right: 0.45em;
  top: 3em;
  display: none;

  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    z-index: -1;
    top: -7px;
    right: 24px;
    transform: rotate(-45deg);
    box-shadow: -1px 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  &:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    z-index: 1;
    top: -7px;
    right: 24px;
    transform: rotate(-45deg);
    background: #fff;
  }

  > div {
    background: ${({ theme: { colors } }) => colors.grayscale.white};
    border-radius: 3px;
    box-shadow: ${({ theme: { shadow } }) => shadow.secondary};
    padding: 0.5em;
    width: 21em;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 2em;
      position: absolute;
      z-index: 1;
      top: -2em;
      right: 0;
    }
  }

  ${({ opened }) =>
    opened &&
    css`
      display: block;
    `};
`

export const Nav = styled.div`
  position: relative;
`

const navStyle = css`
  color: ${({ theme: { colors } }) => colors.grayscale.medium};
  display: flex;
  margin-left: 2em;
  margin-top: 0.2em;
  position: relative;
  transition: color ${({ theme: { transition } }) => transition.ease()};
  z-index: 1;

  &:hover {
    color: ${({ theme: { colors } }) => colors.blue.dark};
  }
`

const NavLinkInternal = styled(Link)`
  ${navStyle};
`

const NavLinkExternal = styled.a`
  ${navStyle};
`

export const NavLink = ({
  to,
  children,
}: {
  to: string
  children: React.ReactNode
}) => {
  const newTab = /mailto:|https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i.test(to)

  if (newTab) {
    return (
      <NavLinkExternal href={to} target="_blank" rel="noreferrer">
        {children}
      </NavLinkExternal>
    )
  }

  return <NavLinkInternal to={to}>{children}</NavLinkInternal>
}

export const NavButton = styled.button`
  ${navStyle};
  margin-top: 0.3em;
`

export const SubNavLink = styled(Link)<IProp>`
  border-radius: 4px;
  display: flex;
  padding: 0.6em 0.5em .5em;
  transition: background ${({ theme: { transition } }) => transition.ease()};

  &:not(:last-child) {
    margin-bottom: 0.5em;
  }

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.grayscale.lighter};
  }

  img {
    margin-top: -1em;
    margin-right: 1em;
    width: 24px;
  }

  h3 {
    ${textLinkStyle}
    font-weight: bold;
  }
  p {
    ${textStyle}
    color: ${({ theme: { colors } }) => colors.grayscale.default};
  }
`
