import * as styledComponents from 'styled-components'

import { IThemeInterface } from './Provider/types'

const {
  default: styled,
  css,
  keyframes,
  ThemeProvider,
  createGlobalStyle,
} = (styledComponents as styledComponents.ThemedStyledComponentsModule<
  any
>) as styledComponents.ThemedStyledComponentsModule<IThemeInterface>

export default styled
export { css, createGlobalStyle, keyframes, ThemeProvider }
