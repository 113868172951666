import * as React from 'react'

interface IExternalProps {
  handleClickOutside: () => void
}

export default (Component: React.ComponentType<{}>) => {
  return class HOC extends React.Component<IExternalProps> {
    wrapper: HTMLDivElement | null = null

    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside, true)
      document.addEventListener('touchstart', this.handleClickOutside, true)
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside, true)
      document.removeEventListener('touchstart', this.handleClickOutside, true)
    }

    handleClickOutside = (e?: any) => {
      if (
        this.wrapper &&
        e.target instanceof Node &&
        this.wrapper.contains(e.target)
      ) {
        return null
      }

      if (typeof this.props.handleClickOutside === 'function') {
        return this.props.handleClickOutside()
      }

      return null
    }

    render() {
      return (
        <div
          ref={node => {
            this.wrapper = node
          }}
        >
          <Component {...this.props} />
        </div>
      )
    }
  }
}
