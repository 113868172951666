import tag from 'clean-tag'
import { Link } from 'gatsby'
import * as React from 'react'

import Arrow from '../../../images/icons/arrow'
import Location from '../../../images/icons/locations'
import styled, { css } from '../../styled'
import { Flex } from '../Helpers'
import { textStyle } from '../Typography'
import { IProp } from './types'

function applyHover() {
  return css`
    svg {
      transition: transform ${({ theme: { transition } }) => transition.ease()};
    }

    &:hover {
      svg {
        transform: translateX(0.2em);
      }
    }
  `
}

const LinkComp = styled(Link)<IProp>`
  ${props => !props.ghost && textStyle};
  ${props => !props.ghost && props.isHoverAnimationActive && applyHover};
  font-size: 1em;
  line-height: 1.6em;
`

export const LinkExternal = styled(tag.a)<IProp>`
  ${props => !props.ghost && textStyle};
  ${props => !props.ghost && props.isHoverAnimationActive && applyHover};
  font-size: 1em;
`

const icons: any = {
  arrow: (
    <Arrow
      minWidth="14px"
      maxWidth="14px"
      flex={0.1}
      ml=".6em"
      display="inline-block"
      direction="right"
    />
  ),
  arrowDown: (
    <Arrow
      minWidth="14px"
      maxWidth="14px"
      flex={0.1}
      ml=".6em"
      display="inline-block"
      direction="down"
    />
  ),
  arrowNortheast: (
    <Arrow
      minWidth="14px"
      maxWidth="14px"
      flex={0.1}
      ml=".4em"
      display="inline-block"
      direction="northeast"
    />
  ),

  location: <Location flex={0.1} mr=".7em" mt=".1em" />,
}

export default function LinkWrap({
  children,
  childrenProps,
  color,
  positionIcon = 'right',
  isHoverAnimationActive = true,
  style,
  to,
  withIcon,
  onClick,
  ...props
}: IProp) {
  const childrenRender = (
    <Flex as="span" alignItems="center" {...childrenProps}>
      {withIcon && positionIcon === 'left' && icons[withIcon]}
      <span>{children}</span>
      {withIcon && positionIcon === 'right' && icons[withIcon]}
    </Flex>
  )

  if (to !== undefined) {
    const isExternal = /mailto:|tel:|https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i.test(
      to
    )
    const isEmail = /mailto:([^\?]*)/i.test(to)

    if (isExternal) {
      return (
        <LinkExternal
          color={color}
          href={to}
          target={isEmail ? '' : '_blank'}
          rel="noreferrer"
          style={style}
          isHoverAnimationActive={isHoverAnimationActive}
          {...props}
        >
          {childrenRender}
        </LinkExternal>
      )
    }
  } else if (onClick) {
    return (
      <LinkComp
        color={color}
        onClick={onClick}
        style={style}
        isHoverAnimationActive={isHoverAnimationActive}
        as="button"
        {...props}
      >
        {childrenRender}
      </LinkComp>
    )
  }

  return (
    <LinkComp
      color={color}
      to={to}
      style={style}
      isHoverAnimationActive={isHoverAnimationActive}
      {...props}
    >
      {childrenRender}
    </LinkComp>
  )
}
