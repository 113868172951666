import tag from 'clean-tag'
import hs from 'hast-to-hyperscript'
import * as React from 'react'

import blacklist from '../../../constants/blacklistAttrs'
import styled, { keyframes } from '../../styled'
import parser from './parser'

const fadeIn = keyframes`
  from {
    transform: translateX(-30%) translateY(-1em);
    opacity: 0;
  }

  to {
    transform: translateX(-30%) translateY(0);
    opacity: 1;
  }
`

export const GlossaryCopy = styled(tag.span).attrs({ blacklist })`
  abbr {
    text-decoration: none;
    position: relative;
    font-style: normal;

    .glossary__term {
      cursor: help;
      background: ${({ theme: { colors } }) => colors.grayscale.lighter};
      display: inline-block;
    }

    &:hover {
      .glossary__tooltip {
        display: block;
        animation: ${fadeIn} 200ms ease;
      }
    }

    .glossary__tooltip {
      z-index: 99;
      position: absolute;
      top: 3em;
      left: 0;
      transform: translateX(-30%);
      display: none;

      font-size: 0.75rem;
      line-height: 1.3;
      letter-spacing: 0.4px;
      text-align: left;
      font-weight: normal;
      color: ${({ theme: { colors } }) => colors.grayscale.black};

      &:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: -1;
        top: -7px;
        right: calc(50% - 8px);
        transform: rotate(-45deg);
        box-shadow: -1px 2px 12px 0 rgba(0, 0, 0, 0.1);
      }

      &:after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: 1;
        top: -7px;
        right: calc(50% - 7px);
        transform: rotate(-45deg);
        background: #fff;
      }

      > span {
        display: block;
        background: ${({ theme: { colors } }) => colors.grayscale.white};
        border-radius: 3px;
        box-shadow: ${({ theme: { shadow } }) => shadow.secondary};
        padding: 1em;
        width: 21em;

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 2em;
          position: absolute;
          z-index: 1;
          top: -2em;
          right: 0;
        }
      }

      a {
        color: ${({ theme: { colors } }) => colors.blue.default};
        display: block;
        margin-top: 0.4em;
      }
    }
  }
`

export interface IChildren {
  children: string
}

const withGlossary = (arg?: any) => ({ children, ...rest }: any) => {
  const [showToolTip, setToolTip] = React.useState(false)

  React.useEffect(() => {
    setToolTip(true)
  }, [])

  const props = {
    ...arg,
    ...rest,
  }
  if (children && children.type === 'root') {
    const checkIsText = (item: any) => {
      const flagNoGlossary = item.properties && item.properties.noGlossary

      if (item.type === 'text' && flagNoGlossary) {
        return {
          ...item,
          value: parser(item.value, true, showToolTip),
        }
      }

      if (Array.isArray(item.children) && item.children.length > 0) {
        return {
          ...item,
          children: item.children.map((child: any) => {
            return checkIsText(child)
          }),
        }
      }

      return item
    }

    const processedChildren = {
      ...children,
      children: children.children.map(checkIsText),
    }

    return (
      <GlossaryCopy {...props}>
        {React.createElement(
          'div',
          props,
          hs(React.createElement, processedChildren)
        )}
      </GlossaryCopy>
    )
  }

  return (
    <>
      {React.Children.map(children, child => {
        if (typeof child === 'string') {
          const html = parser(child, false, showToolTip).join('')

          return (
            <GlossaryCopy {...props}>
              <span dangerouslySetInnerHTML={{ __html: html }} />
            </GlossaryCopy>
          )
        }

        if (
          React.isValidElement(child) &&
          typeof (child.props as IChildren).children === 'string'
        ) {
          return (
            <GlossaryCopy {...props}>
              {React.cloneElement(child, props, [
                parser((child.props as IChildren).children, true, showToolTip),
              ])}
            </GlossaryCopy>
          )
        }

        return <>{child}</>
      })}
    </>
  )
}

export default withGlossary
