import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import mergeEdges from '../../../utils/mergeEdges'
import Search from '../../Search'
import Card from '../../shared/Card'
import { Box, Flex } from '../../shared/Helpers'
import getProjectIcons from '../../shared/Helpers/getProjectIcons'
import getSocialIcons from '../../shared/Helpers/getSocialIcons'
import Link from '../../shared/Link'
import { Caption, FootNote, Text } from '../../shared/Typography'
import Logo from '../Logo'
import { IMenu, IMenuHandle } from '../types'
import MenuButton from './Button'
import { BlockBody, HeaderMobile, HeaderMobileAssign, Wrapper } from './parts'

export default class RenderHeaderMobile extends React.Component<
  IMenuHandle,
  any
> {
  state = { opened: false }

  handleMenu = () => {
    this.setState(({ opened }: IMenuHandle) => ({ opened: !opened }))
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query HeaderMobile {
            allContentYaml {
              edges {
                node {
                  footer {
                    social {
                      tagline
                      menu {
                        label
                        src
                      }
                    }
                    projects {
                      title
                      menu {
                        label
                        src
                      }
                    }
                    foundation {
                      title
                      menu {
                        label
                        src
                      }
                    }
                    resources {
                      title
                      menu {
                        label
                        src
                      }
                    }
                    footNote {
                      title
                      menu {
                        label
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ allContentYaml }) => {
          const data = mergeEdges(allContentYaml)
          const { opened } = this.state

          if (!data) {
            return null
          }

          // FIXME: data shouldn't be `any`
          const {
            social,
            projects,
            foundation,
            resources,
            footNote,
          } = (data as any).footer

          return (
            <>
              <Wrapper opened={opened}>
                {opened && <BlockBody />}
                <MenuButton
                  aria-label="Open menu"
                  opened={opened}
                  onClick={this.handleMenu}
                />
                <HeaderMobile opened={opened}>
                  <Box py="2em" px="1.25em">
                    <Box mb="3em">
                      <Logo />
                    </Box>

                    {projects.menu && projects.menu.length && (
                      <>
                        <Text mt="2.2em" mb="1em" color="white">
                          {projects.title}
                        </Text>
                        {projects.menu.map(menuRender)}
                      </>
                    )}

                    {foundation.menu && foundation.menu.length && (
                      <>
                        <Text mt="2.2em" mb="1em" color="white">
                          {foundation.title}
                        </Text>
                        {foundation.menu.map(menuRender)}
                      </>
                    )}

                    {resources.menu && resources.menu.length && (
                      <>
                        <Text mb="1em" mt="2.2em" color="white">
                          {resources.title}
                        </Text>
                        {resources.menu.map(menuRender)}
                      </>
                    )}

                    <Box pb="2.3em" mt="3.75em">
                      <Text color="white">{social.title}</Text>
                      <Text color="grey">{social.tagline}</Text>

                      <Flex mt="1em">{social.menu.map(socialRender)}</Flex>
                    </Box>
                    <HeaderMobileAssign flexWrap={'wrap'}>
                      <Flex mb={['.5em', 0]}>
                        {footNote.menu.map(legalRender)}
                      </Flex>
                      <FootNote color="greyMedium">{footNote.title}</FootNote>
                    </HeaderMobileAssign>
                  </Box>
                </HeaderMobile>
              </Wrapper>
              <Box display={['block', 'none']}>
                <Search />
              </Box>
            </>
          )
        }}
      />
    )
  }
}

function socialRender({ label, src }: IMenu) {
  if (!label || !src) {
    return null
  }

  return (
    <Box mr="1em" key={label}>
      <a href={src} target="_blank" rel="noreferrer">
        {getSocialIcons(label)}
      </a>
    </Box>
  )
}

function menuRender({ label, src }: IMenu) {
  return (
    <Text mb="1em" key={label}>
      <Link color="grey" to={src}>
        {label}
      </Link>
    </Text>
  )
}

function legalRender({ label, src }: IMenu, index: number, arr: Array<{}>) {
  return (
    <React.Fragment key={label}>
      <FootNote mr=".5em" color="greyMedium">
        <Link to={src}>{label}</Link>
        {arr.length !== index + 1 && (
          <Box as="span" ml=".3em">
            {' '}
            ·
          </Box>
        )}
      </FootNote>
    </React.Fragment>
  )
}
