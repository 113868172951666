import * as React from 'react'

import { Container } from '../shared/Grid'
import { Flex } from '../shared/Helpers'
import MenuDesktop from './desktop'
import Logo from './Logo'
import MenuMobile from './mobile'

export default function renderHeader() {
  return (
    <Container zIndex={3} as="header">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        py={['2em', '3em']}
      >
        <Logo />
        <MenuDesktop />
        <MenuMobile />
      </Flex>
    </Container>
  )
}
