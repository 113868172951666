export default {
  breakpoints: ['40em', '52em', '64em'],
  colors: {
    blue: {
      dark: '#000C20',
      default: '#0070e8',
      light: '#EBF5FF',
      middle: '#0069D9',
    },
    grayscale: {
      black: '#000',
      dark: '#2E3747',
      default: '#8A94A6',
      light: '#E5E7EB',
      lighter: '#F7F7F7',
      medium: '#53627C',
      mediumLighter: '#CBCFD7',
      white: '#fff',
    },
    orange: {
      default: '#F7A736',
    },
    red: {
      default: '#EE3465',
    },
  },
  shadow: {
    default: '0 2px 12px 0 rgba(0, 0, 0, 0.03)',
    secondary: '0 3px 10px 0 rgba(0, 0, 0, 0.2)',
  },
  transition: {
    cubic: (ms = 750) => `cubic-bezier(.19, 1, .22, 1) ${ms}ms`,
    ease: (ms = 200) => `${ms}ms ease-in-out`,
  },
}
