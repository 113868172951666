import * as React from 'react'

import styled, { css } from '../../styled'
import { IMenuHandleMobile } from '../types'

const Line = styled.div``
const Top = styled.div``
const Middle = styled.div``
const Bottom = styled.div``

const Menu = styled.button<IMenuHandleMobile>`
  display: block;
  width: 24px;
  height: 24px;

  margin-bottom: -4em;
  margin-top: 2.1em;
  position: relative;
  z-index: 1000;
  float: right;

  @media screen and (min-width: 40em) {
    display: none;
  }

  ${Line} {
    display: block;
    height: 2px;
    width: 1.3em;
    background: ${({ theme: { colors } }) => colors.grayscale.black};
  }

  ${Top} {
    transform: translateY(-4px);
  }

  ${Bottom} {
    transform: translateY(4px);
  }

  ${({ opened }) =>
    opened &&
    css`
      margin-right: 1.25em;
      
      & div {
        transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
      }

      ${Line} {
        background: ${({ theme: { colors } }) =>
          colors.grayscale.white}; !important;
        transition: all 200ms 100ms cubic-bezier(0.19, 1, 0.22, 1);
      }

      ${Middle} {
        opacity: 0;
      }

      ${Top} {
        transform: translateY(2px);

        ${Line} {
          transform: rotate(-45deg);
        }
      }

      ${Bottom} {
        transform: translateY(-2px);

        ${Line} {
          transform: rotate(45deg);
        }
      }
    `};
`

export default function MenuHeader({
  opened,
  onClick,
  ...props
}: IMenuHandleMobile) {
  return (
    <Menu opened={opened} onClick={onClick} {...props}>
      <Top>
        <Line />
      </Top>
      <Middle>
        <Line />
      </Middle>
      <Bottom>
        <Line />
      </Bottom>
    </Menu>
  )
}
