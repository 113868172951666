import * as React from 'react'

import { Box } from '../../components/shared/Helpers'
import { IProp as IBox } from '../../components/shared/Helpers/types'

type IWays = 'up' | 'down' | 'left' | 'right' | 'northeast'

interface IProp extends IBox {
  direction: IWays
  className?: string
}

const directions = (way: IWays) => {
  const ways: { [key in IWays]: number } = {
    down: 90,
    left: -180,
    northeast: -45,
    right: 0,
    up: -90,
  }

  return ways[way] || ways.right
}

export default ({ direction, ...props }: IProp) => {
  return (
    <Box
      style={{
        display: 'inline-block',
        transform: `rotate(${directions(direction)}deg)`,
      }}
      as="span"
      {...props}
    >
      <svg style={{ width: '100%' }} width="14" height="10" viewBox="0 0 14 10">
        <g fill="none" fillRule="evenodd">
          <path d="M-1 13V-3h16v16z" />
          <g
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          >
            <path d="M1 5h11.018M9 1l4 4-4 4" />
          </g>
        </g>
      </svg>
    </Box>
  )
}
