import * as React from 'react'

import Button from '../shared/Button'
import { Container } from '../shared/Grid'
import { Box, Flex } from '../shared/Helpers'
import Link from '../shared/Link'
import { Text } from '../shared/Typography'
import { Wrapper } from './parts'
import { ICookie } from './types'

interface IProp {
  data: ICookie
}

interface Istate {
  accepted: null | boolean
}

export default class CookiePolicy extends React.Component<IProp, Istate> {
  state = {
    accepted: true,
  }

  componentDidMount = () => {
    this.setState({
      accepted:
        typeof window !== 'undefined'
          ? window.localStorage.getItem('akasha__cookiePolicy') === 'true'
          : null,
    })
  }

  handleCookie = () => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('akasha__cookiePolicy', 'true')
    }

    this.setState({ accepted: true })
  }

  render() {
    const {
      data: { title, cta, accept },
    } = this.props

    const { accepted } = this.state

    return !accepted ? (
      <Container>
        <Wrapper>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Text fontSize={['14px', '16px']}>
              {title}{' '}
              <Link
                style={{ verticalAlign: 'bottom' }}
                color="blue"
                to="privacy-policy"
              >
                {cta}
              </Link>
            </Text>
            <Box>
              <Button
                mt={['1em', 0]}
                ml={[0, '1em']}
                onClick={this.handleCookie}
                ghost
              >
                {accept}
              </Button>
            </Box>
          </Flex>
        </Wrapper>
      </Container>
    ) : null
  }
}
