export default function<R>(data: { edges: { node: R } }): R | null {
  const arr = data && data.edges
  if (!Array.isArray(arr)) {
    return null
  }

  return arr.reduce((prev, current) => {
    const validKey = Object.keys(current.node).reduce((p: {}, c: string) => {
      const obj = current.node[c]

      if (obj !== null) {
        const keys = Object.keys(obj)

        if (keys.filter(item => obj[item]).length) {
          return { ...p, [c]: obj }
        }

        return p
      }

      return p
    }, {})

    return { ...prev, ...validKey }
  }, {})
}
