import { Flex } from '../shared/Helpers'
import Link from '../shared/Link'
import { BlogText } from '../shared/Typography'
import styled, { css } from '../styled'

export const Footer = styled.footer`
  background-color: ${({ theme: { colors } }) => colors.blue.dark};
  color: white;
`

export const FooterAssign = styled(Flex)`
  ${({ theme: { breakpoints, colors } }) =>
    css`
      border-top: 1px solid ${colors.grayscale.dark};
      padding: 1em 0;
      justify-content: space-between;

      @media screen and (max-width: ${breakpoints[0]}) {
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    `};
`

export const SocialLink = styled(Link).attrs({ ghost: true })`
  margin-right: 1em;
  color: ${({ theme: { colors } }) => colors.grayscale.default};
  transition: color ${({ theme: { transition } }) => transition.ease()};

  &:hover {
    color: ${({ theme: { colors } }) => colors.grayscale.light};
  }
`

export const MenuLink = styled(Link).attrs({ ghost: true })`
  display: block;
  color: ${({ theme: { colors } }) => colors.grayscale.default};
  transition: color ${({ theme: { transition } }) => transition.ease()};

  ${BlogText} {
    transition: color ${({ theme: { transition } }) => transition.ease()};
  }
  &:hover {
    color: ${({ theme: { colors } }) => colors.grayscale.light};
    ${BlogText} {
      color: ${({ theme: { colors } }) => colors.grayscale.light};
    }
  }
`
