import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import mergeEdges from '../../utils/mergeEdges'
import { Container } from '../shared/Grid'
import { Box, Flex } from '../shared/Helpers'
import getSocialIcons from '../shared/Helpers/getSocialIcons'
import Link from '../shared/Link'
import { FootNote, Text } from '../shared/Typography'
import Logo from './Logo'
import { Footer, FooterAssign, MenuLink, SocialLink } from './parts'
import { IMenu } from './types'

export default function FooterWrap() {
  return (
    <StaticQuery
      query={graphql`
        query Footer {
          allContentYaml {
            edges {
              node {
                footer {
                  social {
                    tagline
                    menu {
                      label
                      src
                    }
                  }
                  projects {
                    title
                    menu {
                      label
                      src
                    }
                  }
                  foundation {
                    title
                    menu {
                      label
                      src
                    }
                  }
                  resources {
                    title
                    menu {
                      label
                      src
                    }
                  }
                  footNote {
                    title
                    menu {
                      label
                      src
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allContentYaml }) => {
        const data = mergeEdges(allContentYaml)

        if (!data) {
          return null
        }

        // FIXME: data shouldn't be `any`
        const {
          social,
          projects,
          foundation,
          resources,
          footNote,
        } = (data as any).footer

        return (
          <Footer id="footer">
            <Box pt={['4em', '5em']}>
              <Container>
                <Flex
                  flexWrap={'wrap'}
                  pb={['1em', '3em']}
                  justifyContent="space-between"
                >
                  {/* Social */}
                  <Box mb={['4em', 0]} mt={[0, '.7em']}>
                    <Text mb={['1em', '.5em']} color="white">
                      <Logo />
                    </Text>
                    <Text mr="1.5em" mb={['1em', '0']} color="grey">
                      {social.tagline}
                    </Text>
                    <Box width={['100%', 'auto']}>
                      <Flex mt="1em">{social.menu.map(socialRender)}</Flex>
                    </Box>
                  </Box>

                  <Flex flexWrap={'wrap'}>
                    {/* Projects */}
                    {projects.menu && projects.menu.length && (
                      <Box mr={['0rem', '4em']} width={['100%', 'auto']}>
                        <Text mt=".7em" mb="1em" color="white">
                          {projects.title}
                        </Text>
                        {projects.menu.map(menuRender)}
                      </Box>
                    )}

                    {/* Foundation */}
                    {foundation.menu && foundation.menu.length && (
                      <Box mr={['0rem', '4em']} width={['100%', 'auto']}>
                        <Text mt=".7em" mb="1em" color="white">
                          {foundation.title}
                        </Text>
                        {foundation.menu.map(menuRender)}
                      </Box>
                    )}

                    {/* Resources */}
                    {resources.menu && resources.menu.length && (
                      <Box width={['100%', 'auto']}>
                        <Text mt=".7em" mb="1em" color="white">
                          {resources.title}
                        </Text>
                        {resources.menu.map(menuRender)}
                      </Box>
                    )}
                  </Flex>
                </Flex>
                {/*  */}
                <FooterAssign>
                  <Flex>{footNote.menu.map(footNoteRender)}</Flex>
                  <FootNote
                    minWidth={['100%', 'auto']}
                    color="greyMedium"
                    mr=".5em"
                  >
                    {footNote.title}
                  </FootNote>
                </FooterAssign>
              </Container>
            </Box>
          </Footer>
        )
      }}
    />
  )
}

function socialRender({ label, src }: IMenu) {
  if (!label || !src) {
    return null
  }

  return (
    <SocialLink key={label} to={src} aria-label={label}>
      {getSocialIcons(label)}
    </SocialLink>
  )
}

function menuRender({ label, src }: IMenu) {
  return (
    <Text key={label} mb=".5em">
      <MenuLink to={src}>{label}</MenuLink>
    </Text>
  )
}

function footNoteRender({ label, src }: IMenu, index: number, arr: Array<{}>) {
  return (
    <React.Fragment key={label}>
      <FootNote mr=".5em" color="greyMedium">
        <Link color="greyMedium" to={src}>
          {label}
        </Link>
        {arr.length !== index + 1 && (
          <Box as="span" ml=".3em">
            {' '}
            ·
          </Box>
        )}
      </FootNote>
    </React.Fragment>
  )
}
