import { Link } from 'gatsby'
import * as React from 'react'
import * as S from 'react-instantsearch-dom'

import { Box } from '../shared/Helpers'
import * as T from '../shared/Typography'
import * as P from './parts'

const baseUrl = () => {
  if (!window || !window.location) {
    return ''
  }

  return window.location.origin
}

const searchIcon = require('../../images/icons/search.svg')
const closeIcon = require('../../images/icons/close.svg')

interface IState {
  opened: boolean
  searchQuery: string
  hasRefinements: boolean
}

interface IHit {
  hit: {
    category: string
    path: string
    title: string
  }
}

const MESSAGES = {
  allFilters: 'All',
  initialFilter: 'Most visited pages',
  loadMore: 'Load more',
  placeholder: 'Search something',
}

class Search extends React.Component<{}, IState> {
  categorySectionName: string = ''

  state = { opened: false, searchQuery: '', hasRefinements: false }

  blackListMenu: string[] = ['The foundation', 'Project']

  componentDidUpdate() {
    if (!this.state.hasRefinements) {
      this.categorySectionName = ''
    }
  }

  onClick = () => this.setState(({ opened }) => ({ opened: !opened }))

  onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: value })
    this.categorySectionName = ''
  }

  handleRefinements = (items: Array<{}>) => {
    this.setState({ hasRefinements: items.length > 0 })

    return items
  }

  searchItem = ({ hit }: IHit) => {
    const node = (
      <Box mb="2em">
        {!this.state.hasRefinements &&
          this.categorySectionName !== hit.category && (
            <>
              <P.Div />
              <T.Tag mb="1rem">{hit.category}</T.Tag>
            </>
          )}
        <Link to={hit.path}>
          <T.Caption>{hit.title}</T.Caption>

          <T.Text color="grey">
            <S.Highlight attribute="description" hit={hit} />
          </T.Text>
        </Link>
      </Box>
    )

    this.categorySectionName = hit.category

    return node
  }

  filterMenu = (items: Array<{ label: string }>) =>
    items.filter(e => this.blackListMenu.indexOf(e.label) < 0)

  render() {
    const { opened, searchQuery } = this.state
    const { allFilters, placeholder, initialFilter, loadMore } = MESSAGES

    return (
      <>
        <P.SearchButton type="button" onClick={this.onClick}>
          <img src={searchIcon} alt="Open search" />
        </P.SearchButton>

        {opened && (
          <P.Modal>
            <P.BlockBody />

            <P.Wrapper>
              <P.CloseButton type="button" onClick={this.onClick}>
                <img src={closeIcon} alt="Close search" />
              </P.CloseButton>

              <P.CustomStyle>
                <T.Tag>Search</T.Tag>
                <S.InstantSearch
                  appId={process.env.GATSBY_ALGOLIA_APP_ID as string}
                  apiKey={process.env.GATSBY_ALGOLIA_SEARCH_KEY as string}
                  indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME as string}
                >
                  <S.Configure hitsPerPage={4} />
                  <S.SearchBox
                    autoFocus
                    translations={{ placeholder }}
                    onChange={this.onChange}
                  />

                  <S.CurrentRefinements
                    transformItems={this.handleRefinements}
                  />

                  <P.Tab>
                    {!searchQuery.length ? (
                      <P.TabActived>{initialFilter}</P.TabActived>
                    ) : (
                      <>
                        <S.ClearRefinements
                          translations={{ reset: allFilters }}
                        />
                        <S.Menu
                          attribute="category"
                          transformItems={this.filterMenu}
                        />
                      </>
                    )}
                  </P.Tab>

                  <S.InfiniteHits
                    hitComponent={this.searchItem}
                    translations={{ loadMore }}
                  />
                </S.InstantSearch>
              </P.CustomStyle>
            </P.Wrapper>
          </P.Modal>
        )}
      </>
    )
  }
}

export default Search
