import * as React from 'react'

import CookiePolicy from '../components/CookiePolicy'
import Footer from '../components/Footer'
import GlossaryProvider from '../components/Glossary/Consumer'
import Header from '../components/Header'
import ThemeProvider from '../components/Provider'

const Layout: React.FC = ({ children }) => {
  return (
    <ThemeProvider>
      <GlossaryProvider>
        <CookiePolicy />
        <Header />
        {children}
        <Footer />
      </GlossaryProvider>
    </ThemeProvider>
  )
}

export default Layout
