import * as React from 'react'

import { Typography } from '../shared/Typography'
import { createGlobalStyle, ThemeProvider } from '../styled'
import theme from './theme'

const reset = require('./reset.css')
const GlobalStyle = createGlobalStyle`
  ${reset}
`

export default function Provider({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Typography />
        {children}
      </>
    </ThemeProvider>
  )
}
