import tag from 'clean-tag'
import {
  fontSize,
  lineHeight,
  maxWidth,
  minWidth,
  space,
  style,
  textAlign,
  width,
} from 'styled-system'

import blacklist from '../../../constants/blacklistAttrs'
import withGlossary from '../../Glossary/withGlossary'
import styled, { createGlobalStyle, css } from '../../styled'
import { IAplyColor, IProp } from './types'

const fontface = require('./fonts/index.css')

function applyColor(props: IAplyColor): string {
  const { colors } = props.theme

  const map: any = {
    black: colors.grayscale.black,
    blue: colors.blue.default,
    blueDark: colors.blue.dark,
    default: colors.grayscale.black,
    grey: colors.grayscale.default,
    greyDark: colors.grayscale.dark,
    greyLight: colors.grayscale.light,
    greyMedium: colors.grayscale.medium,
    orange: colors.orange.default,
    red: colors.red.default,
    white: colors.grayscale.white,
  }

  if (props.color !== undefined) {
    return map[props.color]
  }

  return map.default
}

const textTransform = style({
  cssProperty: 'textTransform',
  key: 'textTransform',
  prop: 'textTransform',
})

// **
// Base
// **
export const Typography = createGlobalStyle`
  ${fontface}
  
  body, input, button, select {
    font-family: 'AvertaStd', sans-serif;
  }
`

export const base = css`
  font-weight: normal;
  color: ${applyColor};
`

export const custom = css`
  ${space};
  ${textAlign};
  ${fontSize};
  ${lineHeight};
  ${width};
  ${minWidth};
  ${maxWidth};
  ${textTransform};
`

export const baseTitles = css`
  ${base};
  font-weight: 700;
`

// **
// Main Title
// **
export const mainTitleStyle = css`
  ${baseTitles};
  font-size: 2.5rem;
  line-height: 1.075;
  letter-spacing: -0.4px;
  ${custom};
`

export const MainTitle = styled(tag.h1).attrs({ blacklist })<IProp>`
  ${mainTitleStyle};
`

// **
// Title
// **
export const titleStyle = css`
  ${baseTitles};
  font-size: 1.75rem;
  line-height: 1.25;
  ${custom};
`

export const Title = styled(tag.h1).attrs({ blacklist })<IProp>`
  ${titleStyle};
`

// **
// SubTitle
// **
export const subTitleStyle = css`
  ${baseTitles};
  font-size: 1.5rem;
  line-height: 1.25;
  ${custom};
`

export const SubTitle = styled(tag.h2).attrs({ blacklist })<IProp>`
  ${subTitleStyle};
`

// **
// Caption
// **
export const captionStyle = css`
  ${base};
  font-weight: 500;
  font-size: 1.35rem;
  line-height: 1.35;
  ${custom};
`

export const Caption = styled(tag.h3).attrs({ blacklist })<IProp>`
  ${captionStyle};
`

// **
// Description
// **
export const descriptionStyle = css`
  ${base};
  font-size: 1.25rem;
  line-height: 1.35;
  ${custom};
`

export const Description = styled(withGlossary({ as: 'p' }))<IProp>`
  ${descriptionStyle};
`

// **
// BlogText
// **
export const blogTextStyle = css`
  ${base};
  font-size: 1.125rem;
  line-height: 1.5;
  ${custom};
`

export const BlogText = styled(withGlossary({ as: 'p' }))<IProp>`
  ${blogTextStyle};
`

// **
// TextLink
// **
export const textLinkStyle = css`
  ${base};
  font-size: 1.125rem;
  line-height: 1.3;
  font-weight: 500;
  ${custom};
`

export const TextLink = styled(tag.p).attrs({ blacklist })<IProp>`
  ${textLinkStyle};
`

// **
// Text
// **
export const textStyle = css`
  ${base};
  font-size: 1rem;
  line-height: 1.4;
  ${custom};
`

export const Text = styled(tag.p).attrs({ blacklist })<IProp>`
  ${textStyle};
`

// **
// TextBold
// **
export const textStyleBold = css`
  ${base};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4;
  ${custom};
`

export const TextBold = styled(tag.p).attrs({ blacklist })<IProp>`
  ${textStyleBold};
`

// **
// FootNote
// **
export const footNoteStyle = css`
  ${base};
  font-size: 0.875rem;
  line-height: 1.5;
  ${custom};
`

export const FootNote = styled(tag.p).attrs({ blacklist })<IProp>`
  ${footNoteStyle};
`

// **
// Tag
// **
export const tagStyle = css`
  ${base};
  font-size: 0.75rem;
  line-height: 1.3;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  ${custom};
`

export const Tag = styled(tag.p).attrs({ blacklist })<IProp>`
  ${tagStyle};
`

// **
// LinkVerticalSeparator
// **
export const LinkVerticalSeparator = styled(tag.p).attrs({ blacklist })<IProp>`
  ${base};
  ${custom};

  display: inline-block;
  position: relative;
  padding: 0 1em 0 1.5em;

  &:first-child {
    padding-left: 0;
  }

  &:not(:first-child):before {
    position: absolute;
    left: 0;
    bottom: 0;
    line-height: 1em;
    font-size: 1.6em;
    content: '|';
  }
`

// **
// Vertical Separator
// **
export const VerticalSeparator = styled(tag.span).attrs({ blacklist })<IProp>`
  ${base};
  ${custom};
  display: inline-block;
  position: relative;
  font-size: 1.2em;
  padding: 0 0.625em;

  &:before {
    top: 0;
    left: 0;
    content: '|';
  }
`

// **
// Horizontal Line
// **
export const HorizontalLine = styled(tag.hr).attrs({ blacklist })<IProp>`
  ${base};
  ${custom};
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  padding: 0;
`
