import * as React from 'react'

import { Box } from '../../components/shared/Helpers'
import { IProp as IBox } from '../../components/shared/Helpers/types'

export default (props: IBox) => {
  return (
    <Box
      style={{
        display: 'inline-block',
      }}
      as="span"
      {...props}
    >
      <svg width="16" height="20" xmlns="http://www.w3.org/2000/svg">
        <g
          strokeWidth="1.5"
          transform="translate(-2)"
          stroke="#007BFF"
          fill="none"
          fillRule="evenodd"
        >
          <circle cx="10" cy="8" r="3" />
          <path d="M17 8c0-3.979-3.134-7-7-7S3 4.021 3 8c0 2.983 5.94 11.035 7 11.035 1.06 0 7-7.99 7-11.035z" />
        </g>
      </svg>
    </Box>
  )
}
