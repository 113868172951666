import { graphql, StaticQuery } from 'gatsby'
import kebabcase from 'lodash.kebabcase'
import * as React from 'react'

import { IAlphabet, IFrontMatterTerm, IGlossary, ITerm } from '../types'
import regexCollection from './regexCollection'

const query = graphql`
  query queryGlossary {
    allMarkdownRemark(
      filter: { frontmatter: { term: { ne: null } } }
      sort: { fields: frontmatter___term }
    ) {
      edges {
        node {
          frontmatter {
            term
            includes
            excludes
            short
          }
          html
        }
      }
    }
  }
`

const Context = React.createContext({ data: {}, collectionTerms: [] })

const GlossaryData: React.FC = ({ children }) => (
  <StaticQuery
    query={query}
    render={({ allMarkdownRemark: { edges } }) => {
      // Generats an obj = [key: letter]: empty[]
      const alphabet: IAlphabet<
        IFrontMatterTerm
      > = 'abcdefghijklmnopqrstuvwxyz'
        .split('')
        .reduce((prev: IAlphabet<string>, curr) => {
          prev[curr] = []
          return prev
        }, {})

      // Fill those array with the terms
      const data: IGlossary = edges.reduce(
        (prev: IGlossary, { node }: ITerm) => {
          const { frontmatter, ...rest } = node
          const firstLetter = frontmatter.term.charAt(0).toLowerCase()

          prev[firstLetter] = prev[firstLetter].concat([
            { ...frontmatter, ...rest, slug: kebabcase(frontmatter.term) },
          ])

          return prev
        },
        alphabet
      )

      // collection of regex to set the tooltip
      const collectionTerms = regexCollection(edges)

      return (
        <Context.Provider value={{ data, collectionTerms }}>
          {children}
        </Context.Provider>
      )
    }}
  />
)

export { Context }

export default GlossaryData
