const softwareAsset = require('../../../images/icons/software.svg')
const researchAsset = require('../../../images/icons/research.svg')
const hardwareAsset = require('../../../images/icons/hardware.svg')
const communityAsset = require('../../../images/icons/community.svg')

export default function renderIcon(label: string) {
  const icons: any = {
    Community: communityAsset,
    Hardware: hardwareAsset,
    Research: researchAsset,
    Software: softwareAsset,
  }

  return icons[label]
}
