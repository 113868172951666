import { style } from 'styled-system'

export default [
  ...Object.keys(style),
  'opened',
  'dark',
  'ghost',
  'color',
  'textAlign',
  'mr',
  'mb',
  'fontSize',
]
