import kebabcase from 'lodash.kebabcase'

import { IRegexCollection, ITerm } from '../types'

const uuid = (): string =>
  Math.random()
    .toString(36)
    .substring(2)

// Mount a collect of regex expression, terms and description.
// The regex will split the text node and replace by a id
// and then replace the id in a new component
const collectionRegex = (data: any[]) =>
  data.reduce((prev: IRegexCollection[], { node }: ITerm) => {
    const { term, includes, excludes, short } = node.frontmatter
    const description =
      short.length > 125 ? `${short.slice(0, 125).trim()}...` : short

    const excludessArr = Array.isArray(excludes) ? excludes : [] // Ensure it's array
    const includesArr = Array.isArray(includes) ? includes : [] // Ensure it's array
    const termsExcludes = [...excludessArr]
    const termsIncludes = [term, ...includesArr] // `Includes` with term itself

    // Mount the regex with `excludes` terms
    termsIncludes.forEach((inc: string) => {
      // Add to regex collection
      return prev.push({
        description,
        id: uuid(),
        regexExclude: termsExcludes.length
          ? `(${termsExcludes.join('|')})`
          : '',
        regexInclude: `(?:^|\\b)(${termsIncludes.join('|')})(?!\\w)`,
        slug: kebabcase(term),
        term: inc,
      })
    })

    return prev
  }, [])

export default collectionRegex
