import { Link } from 'gatsby'
import * as React from 'react'
import { space } from 'styled-system'

import styled, { css } from '../../styled'
import { IAplyProps, IProp } from './types'

function disabledStyle(props: IAplyProps) {
  return (
    props.disabled &&
    css`
      opacity: 0.2;
    `
  )
}

function themeStyle(props: IAplyProps) {
  const {
    ghost,
    theme: { colors },
  } = props

  if (ghost) {
    return css`
      color: ${colors.blue.default};
      border: 1px solid ${colors.blue.default};

      &:hover {
        border: 1px solid ${colors.blue.middle};
      }
    `
  }

  return css`
    color: ${colors.grayscale.white};
    background: ${colors.blue.default};

    &:hover {
      background: ${colors.blue.middle};
    }

    &:active {
      color: ${colors.grayscale.white};
      background: ${colors.blue.middle};
    }
  `
}

export const buttonStyle = css`
  border-radius: 4px;
  padding: 0.8rem 0.75rem 0.7rem;
  margin-right: 0.5em;
  line-height: 1;
  transition: all ${({ theme: { transition } }) => transition.ease()};
  min-width: 3em;

  ${disabledStyle};
  ${themeStyle};
`

const Button = styled.button<IProp>`
  ${buttonStyle};
  ${space};
`

const CustomLink = styled(Link)<IProp>`
  ${buttonStyle};
  ${space};
`

export default ({ to, children, onClick, ghost, ...props }: IProp) => {
  if (to) {
    return (
      <CustomLink ghost={ghost} to={to} {...props}>
        {children}
      </CustomLink>
    )
  }

  return (
    <Button
      ghost={ghost}
      onClick={onClick}
      aria-label="button"
      role="presentation"
      {...props}
    >
      {children}
    </Button>
  )
}
