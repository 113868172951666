import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import Arrow from '../../../images/icons/arrowTiny'
import mergeEdges from '../../../utils/mergeEdges'
import withOutsideClickHandler from '../../../utils/withOutsideClickHandler'
import Search from '../../Search'
import { Box, Flex } from '../../shared/Helpers'
import getProjectIcons from '../../shared/Helpers/getProjectIcons'
import { IMenu, IMenuHandle, ISubmenu } from '../types'
import { Nav, NavButton, NavLink, Subnav, SubNavLink } from './parts'

const NavWithOutsider = withOutsideClickHandler(Nav)

function menuRender({ opened, openMenu, closeMenu = () => null }: IMenuHandle) {
  return ({ label, src, subMenu }: IMenu) => {
    return (
      <React.Fragment key={src}>
        {subMenu ? (
          <NavWithOutsider key={src} handleClickOutside={closeMenu}>
            <NavButton onClick={openMenu}>
              {label} <Arrow ml=".6em" width=".7em" direction="down" />
            </NavButton>
            {subMenu && (
              <Subnav opened={opened || false}>
                <div>{subMenu.map(subMenuRender)}</div>
              </Subnav>
            )}
          </NavWithOutsider>
        ) : (
          <Nav key={src}>
            <NavLink to={src}>{label}</NavLink>
          </Nav>
        )}
      </React.Fragment>
    )
  }
}

function subMenuRender({ label, src, tagline }: ISubmenu) {
  return (
    <SubNavLink to={src} key={src}>
      <Box mt="1.1em">
        <img alt={label} src={getProjectIcons(label)} />
      </Box>
      <div>
        <h3>{label}</h3>
        <p>{tagline}</p>
      </div>
    </SubNavLink>
  )
}

export default class Header extends React.Component<
  IMenuHandle,
  { opened: boolean }
> {
  state = { opened: false }

  openMenu = () => {
    this.setState({ opened: true })
  }

  closeMenu = () => {
    this.setState({ opened: false })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query Header {
            allContentYaml {
              edges {
                node {
                  menu {
                    label
                    src
                  }
                }
              }
            }
          }
        `}
        render={({ allContentYaml }) => {
          const data: { menu: IMenu[] } | null = mergeEdges(allContentYaml)

          if (!data) {
            return null
          }

          const { opened } = this.state
          const renderMenuWithProps = menuRender({
            closeMenu: this.closeMenu,
            openMenu: this.openMenu,
            opened,
          })

          return (
            <Flex display={['none', 'flex']}>
              {data.menu.map(renderMenuWithProps)}
              <Search />
            </Flex>
          )
        }}
      />
    )
  }
}
